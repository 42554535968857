import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container } from "../components/ui/grid"
import Button from "../components/ui/button"

import thumbnail from "../images/thumbnail-loadmill.png"

const NotFoundPage = ({ location }) => {
  return (
    <Layout location={location} hideFooter className="bg-black min-h-screen">
      <Seo
        title="404"
        description="Page not found."
        url={location.href}
        image={thumbnail}
      />

      <Container className="pt-28 md:pt-32 text-white">
        <title>Not found</title>

        <h1 className="mb-6">
          Page <span className="text-gray-dim">not found</span>
        </h1>

        <p>Sorry, we couldn’t find what you were looking for.</p>

        <Button to="/" label="Go home" color="outlined-blue" className="mt-6" />
      </Container>
    </Layout>
  )
}

export default NotFoundPage
